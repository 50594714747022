import React, { Component, lazy } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Promotions from '../../common/containers/Promotions'
import Grid from '@material-ui/core/Grid';
import CartCommentModal from '../../common/modals/CartCommentModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ProductVariantsModal from '../../common/modals/ProductVariant'
import ProductListItem from "../../products/components/ProductListItem";
import { getProductsBySubcat, addToCart, addProductTooltip, changeSelectedCartTab, setOrderComment, setCommentModalData } from '../../products/actions/products.action';
import { showHeaderCategory, setChosenCat, setCategoriesLoadedFlag, getCategories, setHierarchyLevel, setSelectedCatId } from '../actions/categories.action';
import { setLinksModalOpened, setBookingModalOpened, setUserNeedsModalState } from '../../menu/menu.actions';
import { setMainBackground } from '../../settings/actions/settings.action';
import { getBusyDates } from './../../booking/actions/booking.actions';

import Utils from '../../utils/Utils';
import ImageButton from './ImageButton';
import RowButtonHierarchy from './RowButtonHierarchy';
import ColumnsButtonHierarchy from './ColumnsButtonHierarchy';
// import ImageCategoryImages from './ImageCategoryImages';
import ProductsFooterContainer from '../../common/containers/ProductsFooterContainer'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Redirect } from 'react-router-dom'
import Auth from "../../utils/Auth";
import clsx from 'clsx';
const CategoryVideo = lazy(() => import('./CategoryVideo'));

// import BookingModal from "../../common/modals/BookingModal";

// import ImageCategoryImagesNew from './ImageCategoryImagesNew';
const ImageCategoryImagesNew = lazy(() => import('./ImageCategoryImagesNew'));
const ChooseLinksModal = lazy(() => import('../../common/modals/ChooseLinksModal'));
const BookingModal = lazy(() => import('./../../booking/components/BookingModal'));


let scrollData = {};

const styles = theme => ({
    root: {
        // marginTop: 129,
        // margin: '55px 0 0',
        // backgroundColor: 'black', //down from settings
        width: '100%',

        // Categories underline indicator
        '& .MuiTabs-indicator': {
            backgroundColor: '#FE6902'
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: 'inherit',
            fontWeight: 'bold'
        },
        '& > .MuiPaper-root': {
            zIndex: 1099,
            top: 0,
            // position: 'relative',
            padding: '0'

        },
    },
    rootTabletMode: {
        margin: '65px 0 0',
        '& > .MuiPaper-root': {
            paddingTop: 10,
        },
    },
    initialTopWhiteContainerBugfix: {
        width: '100vw',
        height: '100px',
        background: 'white',
        position: 'absolute',
        margin: 0,
        top: 0,
    },
    categoryName: {
        fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        'flex-grow': 1,
        'max-width': 'none',
        lineHeight: 'initial',
        '&.Mui-selected': {
            color: '#fff !important',
            background: 'rgba(0, 0, 0, 0.74)',
        },
        'border-radius': 3,
        margin: '10px 5px 10px 0',
        color: '#fff',
        padding: 10,
        'min-height': 'auto',
        color: '#1f1f1f'
    },
    categoryNameTabletMode: {
        padding: 15,
    },
    categoryIcon: {
        height: 35,
    },
    swipeableViews: {
        paddingTop: 115,
        // paddingBottom: 80,
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        // height: 'calc(100vh - 114px)',
        WebkitOverflowScrolling: 'touch',
        '& .react-swipeable-view-container': {
            width: '100vw'
        }
    },
    swipeableViewsTabletView: {
        paddingTop: 85,
        // paddingBottom: 80,
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        // height: 'calc(100vh - 114px)',
        WebkitOverflowScrolling: 'touch',
        '& .react-swipeable-view-container': {
            width: '100vw'
        }
    },
    tabsContainer: {
        '& .MuiTabs-flexContainer': {
            'justify-content': 'space-between', //space-around
        },
        '& .MuiTabs-scrollButtons svg': {
            fontSize: '2rem !important',
            fill: '#f66201',
        }
    },
    productsContainer: {
        WebkitOverflowScrolling: 'touch',
        width: '90%',
        margin: '0 auto',
        paddingTop: 15, //DIFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF
        paddingBottom: 70, // temp unable to hive navbar fix
    },
    appBar: {
        position: 'fixed',
        zIndex: 1,
        paddingTop: '55px !important',
    },
    tabletAppBar: {
        position: 'fixed',
        paddingTop: '70px !important',
        zIndex: 1,
    },
    backdrop: {
        zIndex: 2,
        color: '#fff',
        position: 'absolute !important', //backdrop not on the full screen
    },

    // Image gallery
    gridList: {
        // width: 500,
        height: 450,
    },

    promotionsVisibleRightSideRowCats: {
        '@media screen and (min-width: 1000px)': {
            display: 'grid',
            gridTemplateColumns: '65% 35%',
            gridAutoFlow: 'column'
        },
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


class CategoriesHierarchyPage extends Component {
    constructor(props) {
        super(props);
        this.props.setCategoriesLoadedFlag(1); // Used for check in products
        this.state = {
            variantModalOpen: false,
            selectedProduct: null,
            variantModalWithComment: false,
            currHierarchyLevel: 1,
            openCommentModal: false,
            commentModalTitle: '',
            commentVariantId: '',
        }

    }

    componentWillUpdate() {
        const { action, location } = this.props.history;
        // Utils.handleScrollToOnPop(this.props.settings, action, location, this.props.scrollData);
        // console.log('this.props.scrollData',this.props.scrollData);

        // if (action === "POP" || action === "PUSH") {
        //     console.log('action',action);
        //     Utils.handleScrollToOnPop(this.props.settings, action, location, this.props.scrollData);
        // }

        // if (action === "POP") {
        //     console.log('acscrollData[location.pathname]tion', scrollData[location.pathname])

        //     if (this.props.scrollData && this.props.scrollData[location.pathname]) {
        //         console.log('SCROLL TO: ', this.props.scrollData[location.pathname])
        //         //         setTimeout(() =>
        //         //             document.querySelector('[data-class="swipeable-container"]').scrollTo({
        //         //                 left: 0,
        //         //                 top: this.props.scrollData[location.pathname],
        //         //                 behavior: "smooth"
        //         //             })
        //         //         );
        //     } else {
        //         console.log('SCROLL TO TOP :(')
        //         //          if (document.querySelector('[data-class="swipeable-container"]')) {
        //         //              document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 });
        //         //          }
        //     }
        // } else {
        //     console.log('SCROLL TO TOP  222222 :(')
        //     //     if (document.querySelector('[data-class="swipeable-container"]')) {
        //     //          setTimeout(document.querySelector('[data-class="swipeable-container"]').scrollTo({ left: 0, top: 0 }));
        //     //     }
        // }
    }



    componentWillMount() {
        // this.props.getCategories();
        this.props.showHeaderCategory(null);
    }

    componentDidMount() {
        this.props.setMainBackground('linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) )');
    }

    componentWillReceiveProps(nextProps) {
        // if(process.env == "production" && (!nextProps.client || Object.keys(nextProps.client).length === 0 && nextProps.client.constructor === Object)) {
        //     nextProps.history.push("/404");
        // }
        // initially setting the curr cat id in global store
        // console.log("CAT NEXT PROPS _________", nextProps, nextProps.history);
        // if(nextProps.selectedCatId == 0 && nextProps.selectedCat.id) {
        //     this.props.setSelectedCatId(nextProps.selectedCat.id);
        //     Utils.setDataForTrack(nextProps.selectedCat.id, nextProps.openedProductId, nextProps.history.location.pathname )
        // }
    }

    handleChange = (event, newValue) => {
        const { translations } = this.props;
        const { action, location } = this.props.history;

        Utils.handleScrollToOnPop(this.props.settings, action, location, this.props.scrollData, true);

        this.props.setChosenCat(newValue);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[newValue] ? Object.values(this.props.allCatsHierarchy)[newValue].slug : "";
        let cat = this.props.categories && this.props.categories[newValue] ? this.props.categories[newValue] : [];

        if (cat) {

            if (cat.type == 'link') {
                this.handleLinkCatRedirect(cat);
            } else {
                this.props.setSelectedCatId(cat.id);

                if (cat.hierarchyLevel > 1) {
                    // this.props.showHeaderCategory(cat.name)
                    this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
                }
                this.props.history.push("/" + this.props.placeSlug + "/" + cat.slug);
            }

        }
        // let catSlug = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].slug : "";
        // let catLevel = this.props.categories && this.props.categories[newValue] ?  this.props.categories[newValue].hierarchyLevel : 1;
    };

    // On swipe event
    handleSwipeChangeIndex = index => {
        this.props.setChosenCat(index);
        // let catSlug = this.props.allCatsHierarchy && Object.values(this.props.allCatsHierarchy)[index] ? Object.values(this.props.allCatsHierarchy)[index].slug : "";
        let cat = this.props.categories && this.props.categories[index] ? this.props.categories[index] : null;

        let catSlug = cat ? cat.slug : "";
        let catId = cat ? cat.id : 0;
        this.props.setSelectedCatId(catId);

        if (cat.type == 'link') {
            this.handleLinkCatRedirect(cat);
        } else {
            this.props.history.push("/" + this.props.placeSlug + "/" + catSlug);
        }

        
        Utils.handleScrollToOnPop(this.props.settings, "SWIPE")
        
    };

    handleLinkCatRedirect(cat) {
        var links = cat.translations[this.props.currLang] && cat.translations[this.props.currLang].links || cat.links

        if (cat.type == 'link') {
            // Auth.setRoomNumInToken(); //TODO
            if ((Object.keys(JSON.parse(links)).length) == 1) {
                let roomNumber = Auth.getRoomNumInToken() && ("?room_number=" + Auth.getRoomNumInToken()) || "";
                let link = JSON.parse(links)[0].link;

                if (link) {
                    if (link.indexOf('/feedback') != -1) {
                        this.props.history.push('/' + this.props.placeSlug + "/feedback");
                        return;
                    } else if (!link.includes('https://')) {
                        this.props.history.push(link);
                        return;
                    } else {
                        window.location = link + roomNumber;
                        return;
                    }
                }
            } else {
                this.props.setLinksModalOpened(true, links);
            }
        }
    }

    categoryClickHandler = (id) => {
        const { translations } = this.props;
        const { action, location } = this.props.history;
        this.props.setSelectedCatId(id);

        Utils.handleScrollToOnPop(this.props.settings, action, location, this.props.scrollData, true);

        // const { subcategory } = this.props;
        var newLevel = this.props.allCatsHierarchy[id].hierarchyLevel;
        var type = this.props.allCatsHierarchy[id].type;
        var open_call_waiter = this.props.allCatsHierarchy[id].open_call_waiter;

        // var links = this.props.allCatsHierarchy[id].links;
        var links = this.props.allCatsHierarchy[id].translations[this.props.currLang] && this.props.allCatsHierarchy[id].translations[this.props.currLang].links || this.props.allCatsHierarchy[id].links

        if (type == 'link') {
            if (navigator.onLine) {
                // Auth.setRoomNumInToken(); //TODO
                if ((Object.keys(JSON.parse(links)).length) == 1) {
                    let roomNumber = Auth.getRoomNumInToken() && ("?room_number=" + Auth.getRoomNumInToken()) || "";
                    window.location = JSON.parse(links)[0].link + roomNumber
                } else {
                    this.props.setLinksModalOpened(true, links);
                }
            }
        } else if (open_call_waiter == 1) {
            this.props.setUserNeedsModalState(!this.props.userNeedsModalOpened)
        } else {

            this.props.setHierarchyLevel(newLevel);
            if (parseInt(newLevel) > 1) {
                // this.props.showHeaderCategory(this.props.allCatsHierarchy[id].name)
                this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '')
            }
            this.setState({ currHierarchyLevel: newLevel });
            this.props.setChosenCat(0);
            this.props.history.push("/" + this.props.placeSlug + "/" + this.props.allCatsHierarchy[id].slug);
        }
    }


    // addToCartBtnClicked = (id) => {
    //     const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];

    //     if (currProduct.product_variants) {
    //         if (currProduct.product_variants.length === 1) {
    //             this.addProdToCart(currProduct.id, currProduct.product_variants[0].id, currProduct)
    //         } else {
    //             // Open variant modal
    //             this.setState({ variantModalOpen: true, selectedProduct: currProduct })
    //         }
    //     }

    // }



    // SAME
    // Opens variant modal or adds prod to cart
    addToCartBtnClicked = (id, productVariantId = 0) => {
        const currProduct = Object.values(this.props.products.all_products).filter(p => p.id === id)[0];
        // const currProduct = products.filter(p => p.id === id)[0];

        if (Utils.hasFunctionalityModule('booking') && currProduct.type == 'booking') {
            this.props.setBookingModalOpened(true, { 'productId': id, 'productVariantId': productVariantId })
            this.props.getBusyDates({ "productVariantId": productVariantId, "categoryId": this.props.catHierarchyId })

        } else {
            if (this.props.settings && this.props.settings.variant_modal_with_comment && this.props.settings.variant_modal_with_comment.value == 1) {
                this.setState({ variantModalOpen: true, selectedProduct: currProduct, variantModalWithComment: true })
            } else {
                if (currProduct.product_variants) {
                    if (currProduct.product_variants.length === 1 || productVariantId > 0) {
                        this.addProdToCart(currProduct.id, productVariantId ? productVariantId : currProduct.product_variants[0].id, currProduct)
                    } else {
                        // Open variant modal
                        this.setState({ variantModalOpen: true, selectedProduct: currProduct })
                    }
                }
            }
        }

    }

    addProdToCart = (product_id, product_variant_id, product) => {
        if (this.state.variantModalOpen) {
            this.setState({ variantModalOpen: false });
        }

        const requiredProductIngridients = product.product_ingredients.filter(pi => pi.type == 'required' && this.props.productIngredients[pi.ingredientId] && this.props.productIngredients[pi.ingredientId].active == true);

        let defautRequiredProductIngridient = 0;
        if (requiredProductIngridients && requiredProductIngridients[0] && requiredProductIngridients[0].ingredientId) {
            defautRequiredProductIngridient = requiredProductIngridients[0].ingredientId;
        }

        const selectedIngredients = {
            required: defautRequiredProductIngridient,
            addable: [],
            removable: [],
        }

        this.showAddedProductTooltip(product_id, product_variant_id, product, selectedIngredients);
        this.props.changeSelectedCartTab(0); // select default tab "Cart"
        this.props.addToCart(product_id, product_variant_id, product, selectedIngredients, this.props.selectedCat.id);
    };


    // Shows tooltip
    showAddedProductTooltip = (id, product_variant_id, product, selectedIngredients) => {
        // let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && Object.keys(this.props.products.to_order).length > 0) {
        //     const addedProductVariant = this.props.products.to_order.find(pv => pv.product_variant_id === product_variant_id);
        //     if (addedProductVariant) {
        //         totalProdQuantity = parseInt(addedProductVariant.quantity) + 1;
        //     }
        // }

        let totalProdQuantity = 1;
        // if (this.props.products && this.props.products.to_order && this.props.products.to_order[0] && this.props.products.to_order[0].length > 0) {
        totalProdQuantity = Utils.getCartProductQuantity(product_variant_id, selectedIngredients);
        // }


        let choosedProductVariant = product.product_variants.filter(pv => pv.id === product_variant_id)[0];
        let tooltipText = totalProdQuantity + ' x ' + product.name + (choosedProductVariant.size && choosedProductVariant.size != ' ' ? ' - /' + choosedProductVariant.size + '/' : '');

        // Add to cart tooltip
        const addProdTooltip = (id, tooltipText) => this.props.addProductTooltip(id, tooltipText);
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
            this.setState({ timeout: null });
        }
        addProdTooltip(id, tooltipText);
        let timeOut = setTimeout(function () { addProdTooltip(0, tooltipText); }, 2500);
        this.setState({ timeout: timeOut });
    }
    // SAME ^


    addCommentToOrder = (variantId, comment) => {
        let stateComments = Object.values(this.props.products.order_comment);
        let validatedComments = Utils.validatedCommentObject(stateComments, variantId, comment)
        if (validatedComments) {
            this.props.setOrderComment(validatedComments)
        }
    }


    render() {

        const { classes, categories, chosenCat, settings, translations, isLoadingHierarchy, registeredUserDiscount } = this.props;
        const subcategories_design = settings.subcategories_design && settings.subcategories_design.value ? settings.subcategories_design.value : 1;

        const hasSubcategories = Object.values(this.props.allCatsHierarchy).findIndex(c => c.children.length > 0);

        // Same as AppNavbar
        const show_restaurant_location_position = Utils.getSettingsValue(settings, 'show_restaurant_location_position');
        const change_language_position = Utils.getSettingsValue(settings, 'change_language_position');
        const reservations_position = Utils.getSettingsValue(settings, 'reservations_position');
        const search_btn_position = Utils.getSettingsValue(settings, 'search_btn_position');
        const about_restaurant_position = Utils.getSettingsValue(settings, 'about_page_btn_position');
        const show_change_place = Utils.getSettingsValue(settings, 'show_change_place_footer');

        const show_restaurant_location_position_footer = show_restaurant_location_position == 'footer' && (settings && settings.show_restaurant_location && settings.show_restaurant_location.value == 1)
        const change_language_position_footer = change_language_position == 'footer' //always is shown
        const reservations_position_footer = reservations_position == 'footer' && Utils.getSettingsValue(settings, 'reservations_url');
        const search_btn_position_footer = search_btn_position == 'footer' && (Utils.getSettingsValue(settings, 'call_btn_instead_of_search') != '');
        const about_restaurant_position_footer = about_restaurant_position == 'footer' // && (Utils.getSettingsValue(settings, 'about_page_html') != ''); - about_page_html is not used anymore (old ckeditor logic)
        const change_place_position_footer = show_change_place == 1 // && (Utils.getSettingsValue(settings, 'about_page_html') != ''); - about_page_html is not used anymore (old ckeditor logic)

        let footerNavigatonCountLinks = 0;
        if (show_restaurant_location_position_footer) { footerNavigatonCountLinks++; }
        if (change_language_position_footer) { footerNavigatonCountLinks++; }
        if (reservations_position_footer) { footerNavigatonCountLinks++; }
        if (search_btn_position_footer) { footerNavigatonCountLinks++; }
        if (about_restaurant_position_footer) { footerNavigatonCountLinks++; }
        if (change_place_position_footer) { footerNavigatonCountLinks++; }

        const currentCat = categories[this.props.catIndex]

        return (
            <div className={Auth.isTabletMode() ? clsx(classes.root, classes.rootTabletMode) : classes.root}
                style={subcategories_design == '1'
                    && this.props.selectedCat && this.props.selectedCat.children && this.props.selectedCat.children.length > 0
                    && Utils.getSettingsValue(settings, 'remove_background_color_image_categories') != '1'
                    && Utils.getSettingsValue(settings, 'pdf_menu_enabled') != '1' ? { backgroundColor: '#e6e6e6' } : {}} data-class="all-products-container">

                {subcategories_design == '1' ?
                    <div className={classes.initialTopWhiteContainerBugfix}></div>
                    :
                    null
                }

                <Backdrop open={isLoadingHierarchy} className={classes.backdrop}>
                    <CircularProgress className="is-loading" />
                </Backdrop>

                <AppBar position="static" color="default" className={Auth.isTabletMode() ? classes.tabletAppBar : classes.appBar}
                    style={
                        {
                            background: 'rgba(241, 241, 241)'
                        }
                    }>
                    <Tabs
                        value={this.props.catIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="fullWidth"
                        scrollButtons="on"
                        variant="scrollable"
                        aria-label="full width tabs example"
                        className={classes.tabsContainer}
                    >
                        {Object.values(categories).map((category, k) => (
                            <Tab key={k} component="h2"
                                label={Utils.splitCatSubcatOnTwoRowsBySpace((category.translations[this.props.currLang] && category.translations[this.props.currLang].name || category.name), settings)}
                                // icon={<img className={classes.categoryIcon} src={Utils.getCategoryImagePath(category.icon)} />}
                                {...a11yProps(++k)}
                                className={Auth.isTabletMode() ? clsx(classes.categoryName, classes.categoryNameTabletMode) : classes.categoryName}
                                data-class="each-category"
                            />
                        ))}
                    </Tabs>
                </AppBar>


                {Utils.getSettingsValue(settings, 'pdf_menu_enabled') == 1 ?
                    <React.Fragment>

                        {Object.values(categories).map((category, k) => (
                            <TabPanel key={k} value={this.props.catIndex} index={k}>

                                {category.type == 'image' ?
                                    <ImageCategoryImagesNew
                                        cat={category}
                                    />
                                    :
                                    <React.Fragment key={k}>
                                        {category.children.length == 0 ?
                                            <React.Fragment>
                                                <Promotions settings={settings} translations={translations.translations} />
                                                <Grid
                                                    className={classes.productsContainer}
                                                    container
                                                    direction="row"
                                                    //   justify="space-between"
                                                    //   justify="center"
                                                    // alignItems="center"
                                                    spacing={3}
                                                >

                                                    {Object.values(this.props.allProducts).sort(Utils.sortPriority())
                                                        .filter(product => product.catHierarchyIds.includes(category.id)).map((product, kkk) => (
                                                            <ProductListItem
                                                                key={kkk}
                                                                k={kkk}
                                                                // hideProductImage={1}
                                                                // showSubCatInProductHeader={1}
                                                                category={category}
                                                                catSlug={this.props.catSlug}
                                                                product={product}
                                                                settings={settings}
                                                                addProdToCart={this.addProdToCart}
                                                                addToCartBtnClicked={this.addToCartBtnClicked}
                                                                translations={translations}
                                                                currLang={this.props.currLang}
                                                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                                            // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                                            // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                                            // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                                            />
                                                        ))
                                                    }
                                                </Grid>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>

                                                {subcategories_design == '1' ?
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <ImageButton
                                                            settings={settings}
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    (
                                                        subcategories_design == '3' ?
                                                            <React.Fragment>
                                                                <Promotions settings={settings} translations={translations.translations} />
                                                                <ColumnsButtonHierarchy
                                                                    settings={settings}
                                                                    items={category.children}
                                                                    categoryClickHandler={this.categoryClickHandler}
                                                                    translations={translations.translations}
                                                                    currLang={this.props.currLang}
                                                                    registeredUserDiscount={registeredUserDiscount}
                                                                />
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <Promotions settings={settings} translations={translations.translations} />
                                                                <RowButtonHierarchy
                                                                    settings={settings}
                                                                    items={category.children}
                                                                    categoryClickHandler={this.categoryClickHandler}
                                                                    translations={translations.translations}
                                                                    currLang={this.props.currLang}
                                                                    registeredUserDiscount={registeredUserDiscount}
                                                                />
                                                            </React.Fragment>
                                                    )
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>

                                }
                            </TabPanel>
                        ))}

                    </React.Fragment>

                    :

                    <SwipeableViews className={Auth.isTabletMode() ? classes.swipeableViewsTabletView : classes.swipeableViews}
                        data-class="swipeable-container"
                        index={this.props.catIndex}
                        onChangeIndex={this.handleSwipeChangeIndex}
                        enableMouseEvents
                        animateTransitions={false}
                    >
                        {Object.values(categories).map((category, k) => (
                            <TabPanel key={k} value={this.props.catIndex} index={k}
                                style={{
                                    // paddingBottom: footerNavigatonCountLinks > 0 ? 112 : 0,
                                    backgroundImage: currentCat && currentCat.background_image ? 'url(' + (Utils.getSettingsPath(currentCat.background_image)) + ')' : 'initial',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            >

                                {category.type == 'image' ?
                                    <ImageCategoryImagesNew
                                        cat={category}
                                    />
                                    :
                                    <React.Fragment key={k}>
                                        {category.children.length == 0 ?
                                            <React.Fragment>
                                                {hasSubcategories == -1 ?
                                                    <Promotions settings={settings} translations={translations.translations} generalPromotions={this.props.generalPromotions} />
                                                    :
                                                    null
                                                }

                                                {category.video_name ?
                                                    <CategoryVideo
                                                        category={category}
                                                        settings={settings}
                                                        currLang={this.props.currLang}
                                                    />
                                                    :
                                                    null
                                                }


                                                <Grid
                                                    className={classes.productsContainer}
                                                    container
                                                    direction="row"
                                                    //   justify="space-between"
                                                    //   justify="center"
                                                    // alignItems="center"
                                                    spacing={3}
                                                >

                                                    {Object.values(this.props.allProducts).sort(Utils.sortPriority())
                                                        .filter(product => product.catHierarchyIds.includes(category.id)).map((product, kkk) => (
                                                            <ProductListItem
                                                                key={kkk}
                                                                k={kkk}
                                                                // hideProductImage={1}
                                                                // showSubCatInProductHeader={1}
                                                                category={category}
                                                                catSlug={this.props.catSlug}
                                                                product={product}
                                                                settings={settings}
                                                                addProdToCart={this.addProdToCart}
                                                                addToCartBtnClicked={this.addToCartBtnClicked}
                                                                translations={translations}
                                                                currLang={this.props.currLang}
                                                                setCommentModalProps={(commentModalTitle, commentVariantId) => { this.props.setCommentModalData(true, commentModalTitle, commentVariantId) }}
                                                                orderAllowed={category.order_allowed}
                                                            // openCommentModal={() => { this.setState({ openCommentModal: true }) }}
                                                            // modalTitle={() => { this.setState({ modalTitle: modalTitle }) }}
                                                            // commentVariantId={() => { this.setState({ commentVariantId: commentVariantId }) }}
                                                            />
                                                        ))
                                                    }

                                                </Grid>

                                                {Utils.getSettingsValue(settings, 'show_footer') != '' ?
                                                    <ProductsFooterContainer settings={settings} translations={translations} />
                                                    :
                                                    null
                                                }

                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {subcategories_design == '1' ?
                                                    <React.Fragment>
                                                        <Promotions settings={settings} translations={translations.translations} />
                                                        <ImageButton
                                                            settings={settings}
                                                            items={category.children}
                                                            categoryClickHandler={this.categoryClickHandler}
                                                            currLang={this.props.currLang}
                                                        />
                                                    </React.Fragment>
                                                    :
                                                    (
                                                        subcategories_design == '3' ?
                                                            <div className={
                                                                ((settings.promotions_html && settings.promotions_html.value)
                                                                    || (settings.promotions_text && settings.promotions_text.value)
                                                                    || (registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0)
                                                                )
                                                                    ? classes.promotionsVisibleRightSideRowCats : {}} >
                                                                <Promotions settings={settings} translations={translations.translations} />
                                                                <ColumnsButtonHierarchy
                                                                    settings={settings}
                                                                    items={category.children}
                                                                    categoryClickHandler={this.categoryClickHandler}
                                                                    translations={translations.translations}
                                                                    currLang={this.props.currLang}
                                                                    registeredUserDiscount={registeredUserDiscount}
                                                                />
                                                            </div>
                                                            :
                                                            <div className={
                                                                ((settings.promotions_html && settings.promotions_html.value)
                                                                    || (settings.promotions_text && settings.promotions_text.value)
                                                                    || (registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0)
                                                                )
                                                                    ? classes.promotionsVisibleRightSideRowCats : {}} >
                                                                <Promotions settings={settings} translations={translations.translations} />
                                                                <RowButtonHierarchy
                                                                    settings={settings}
                                                                    items={category.children}
                                                                    categoryClickHandler={this.categoryClickHandler}
                                                                    translations={translations.translations}
                                                                    currLang={this.props.currLang}
                                                                    registeredUserDiscount={registeredUserDiscount}
                                                                />
                                                            </div>
                                                    )

                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>

                                }
                            </TabPanel>
                        ))}

                    </SwipeableViews>
                }

                {
                    this.state.openCommentModal ?
                        <CartCommentModal
                            open={this.state.openCommentModal}
                            modalTitle={this.state.commentModalTitle}
                            commentVariantId={this.state.commentVariantId}
                            comments={this.props.products.order_comment}
                            onClose={() => { this.setState({ openCommentModal: false }) }}
                            onSubmit={this.addCommentToOrder}
                        />

                        :
                        null
                }
                <ProductVariantsModal
                    open={this.state.variantModalOpen}
                    variantModalWithComment={this.state.variantModalWithComment}
                    product={this.state.selectedProduct}
                    addProdToCart={this.addProdToCart}
                    order_comment={this.props.products.order_comment}
                    setOrderComment={this.props.setOrderComment}
                    closeProductVariantModalHandler={() => this.setState({ variantModalOpen: !this.state.variantModalOpen })}
                    settings={settings}
                    translations={translations}
                    currLang={this.props.currLang}
                    productIngredients={this.props.productIngredients}
                />

                {this.props.linksModalOpened ?
                    <ChooseLinksModal />
                    :
                    null
                }

                {Utils.hasFunctionalityModule('booking') ?
                    <BookingModal />
                    :
                    null
                }

            </div >
        );

    }
}

const mapStateToProops = (state, ownProps) => {
    var catsHierarchy = state.categories.categories_hierarchy || [];
    let catSlug = ownProps.match.params.catSlug !== "null" ? ownProps.match.params.catSlug : "";

    let selectedCat = catsHierarchy && Object.values(catsHierarchy).filter(c => c.slug === catSlug)[0] ? Object.values(catsHierarchy).filter(c => c.slug === catSlug).sort(Utils.sortPriority())[0] : null;

    if (!selectedCat) {
        let mainParent = catsHierarchy && Object.values(catsHierarchy).filter(c => c.hierarchyLevel == 1).sort(Utils.sortPriority())[0];
        selectedCat = mainParent || []; //catsHierarchy && Object.values(catsHierarchy)[0] ? Object.values(catsHierarchy)[0] : [];
    }


    var level = selectedCat.hierarchyLevel; // state.categories.curr_hierarchy_level;

    var currCats = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === level && c.parentId === selectedCat.parentId && (c.show_cat_only_for_spot_orders == 1 ? Auth.getTokenOrderType() == 1 : true) && (c.show_cat_only_for_delivery_orders == 1 ? Auth.getTokenOrderType() == 2 : true) && (Auth.getTokenOrderType() == 1 ? c.hide_cat_only_for_spot_orders == 0 : true) && (Auth.getTokenOrderType() == 2 ? c.hide_cat_only_for_delivery_orders == 0 : true));

    currCats = Object.values(currCats).filter(c => (c.show_only_for_spot_browse == 0 ? true : (c.show_only_for_spot_browse == 1 && Auth.getTokenOrderType() == 4 ? true : false)));
    currCats = Object.values(currCats).filter(c => (c.hide_only_for_spot_browse == 0 ? true : (c.hide_only_for_spot_browse == 1 && Auth.getTokenOrderType() == 4 ? false : true)));

    currCats = Object.values(currCats).filter(c => (c.show_cat_only_for_delivery_orders == 0 ? true : (c.show_cat_only_for_delivery_orders == 1 && Auth.getTokenOrderType() == 2 ? true : false)));
    currCats = Object.values(currCats).filter(c => (c.hide_cat_only_for_delivery_orders == 0 ? true : (c.hide_cat_only_for_delivery_orders == 1 && Auth.getTokenOrderType() == 2 ? false : true)));

    currCats = currCats.sort(Utils.sortPriority());

    let catIndex = currCats && Object.values(currCats).findIndex(e => e.id === selectedCat.id);

    if (catIndex === -1) catIndex = 0;

    let selectedCatChildren = Object.values(catsHierarchy).filter(c => c.hierarchyLevel === selectedCat.hierarchyLevel + 1 && c.parentId === selectedCat.id);
    selectedCat.children = selectedCatChildren;

    let allProducts = state.products.all_products || [];
    let currProducts = Object.values(allProducts).filter(p => (p.show_only_for_spot_browse == 0 ? true : (p.show_only_for_spot_browse == 1 && Auth.getTokenOrderType() == 4 ? true : false)));

    currProducts = Object.values(currProducts).filter(p => (p.hide_only_for_spot_browse == 0 ? true : (p.hide_only_for_spot_browse == 1 && Auth.getTokenOrderType() == 4 ? false : true)));

    // if(selectedCat) {
    //     setHierarchyLevel(selectedCat.hierarchyLevel);
    // }

    // console.log(`Auth.getTokenOrderType()`, )


    return {
        catSlug: catSlug || "",
        placeSlug: ownProps.match.params.place !== "null" ? ownProps.match.params.place : "",
        allCatsHierarchy: catsHierarchy,
        categories: currCats, //state.categories.categories,
        catIndex: catIndex,
        selectedCat: selectedCat,
        selectedCatId: state.categories.selected_cat_hierarchy_id || 0,
        openedProductId: state.products.view_product_page_id || 0,
        client: state.client || null,

        isLoadingHierarchy: state.categories.hierarchy_loading || false,
        subcategory: state.products.subcat_products,
        chosenCat: state.categories.chosen_cat || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        currLang: state.lang.currLang,
        products: state.products || [],
        allProducts: currProducts, //state.products.all_products || [],
        scrollData: state.menu.scrollData || null,
        linksModalOpened: state.menu.linksModalOpened || false,
        catHierarchyId: state.categories.selected_cat_hierarchy_id || 0,
        registeredUserDiscount: state.registeredUserDiscount.registered_user_discounts || [],
        productIngredients: state.products.productIngredients || [],
    };
};

export default withStyles(styles)(connect(mapStateToProops, {
    showHeaderCategory, getProductsBySubcat, setChosenCat, setCategoriesLoadedFlag,
    getCategories, addToCart, addProductTooltip, changeSelectedCartTab, setMainBackground, setOrderComment,
    setHierarchyLevel, setCommentModalData, setSelectedCatId, setLinksModalOpened, setBookingModalOpened, getBusyDates, setUserNeedsModalState
})(CategoriesHierarchyPage));
